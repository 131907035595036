$breakpoint-tablet: 768px;

$background-color-light: rgba(229, 221, 232, 0.92);
$text-color-light: #363636;
$border-color-light: #744d87;

$background-color-dark: rgba(39, 30, 38, 0.92);
$text-color-dark: #ffffff;
$border-color-dark: #c890ef;

$font-size-xxlarge: 1.75em;
$font-size-xlarge: 1.5em;
$font-size-large: 1.125em;
$font-size-medium: 1em;
$font-size-small: 0.875em;
$font-size-xsmall: 0.75em;
