@import "../../variables.scss";

.button {
  background: linear-gradient(#744d87, #695ba7);
  border-radius: 16px;
  padding: 1.25rem 3rem;
  color: #ffffff;
  box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.12);
  border: none;
  font-weight: 500;
  font-size: $font-size-xlarge;
  cursor: url("../../../../public/images/cursor/cat-pow.png"), none !important;
  &:hover {
    background: linear-gradient(#802ca7, #411ce6);
  }
}

@media (max-width: $breakpoint-tablet) {
  .button {
    padding: 1rem 2rem;
  }
}
