@import "../../variables.scss";

.wrapper {
  padding: 3rem 5rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      width: 10rem;
      height: 10rem;
      margin-left: -10rem;
      transform: rotateY(-180deg);
    }
    h1 {
      font-weight: 500;
    }
    .pows {
      display: flex;
      position: absolute;
      right: -8rem;
      top: 0;
      .pow1 {
        margin-right: -2rem;
        transform: rotate(-30deg);
        svg {
          width: 3rem;
          height: 3rem;
        }
      }
      .pow2 {
        svg {
          width: 5rem;
          height: 8rem;
        }
      }
    }
  }
  .contacts {
    display: flex;
    gap: 3rem;
    margin-top: 4rem;
    margin-bottom: 6rem;
    .social {
      background-image: url("../../../../public/images/contact-info-desktop.svg");
      width: 133px;
      height: 133px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: url("../../../../public/images/cursor/cat-pow.png"), none !important;
      img {
        width: 4rem;
        height: 4rem;
      }
      &:hover {
        img {
          width: 5rem;
          height: 5rem;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .wrapper {
    width: calc(100% - 3rem);
    padding: 5rem 1.5rem;
    .title {
      .title_image {
        width: 4rem;
        height: 4rem;
        margin-right: -3rem;
        position: absolute;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .pows {
        position: absolute;
        right: -6rem;
        top: -2rem;
      }
    }
    .contacts {
      gap: 1rem;
      .social {
        background-image: url("../../../../public/images/contact-info-mobile.svg");
        width: 65px;
        height: 65px;
        img {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}
