@import "../../variables.scss";

.checkbox_wrapper_5 .check {
  --size: 40px;
  position: relative;
  background: #c3becd;
  border: 1px solid #ffffff;
  line-height: 0;
  perspective: 400px;
  font-size: var(--size);
  display: flex;
  cursor: url("../../../../public/images/cursor/cat-pow.png"), none !important;
}

.checkbox_wrapper_5 .check input[type="checkbox"],
.checkbox_wrapper_5 .check label,
.checkbox_wrapper_5 .check label::before,
.checkbox_wrapper_5 .check label::after,
.checkbox_wrapper_5 .check {
  appearance: none;
  display: inline-block;
  border-radius: var(--size);
  border: 0;
  transition: 0.35s ease-in-out;
  box-sizing: border-box;
  cursor: url("../../../../public/images/cursor/cat-pow.png"), none !important;
}

label {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  background-color: #c3becd;
  border: 1px solid #ffffff;
  cursor: url("../../../../public/images/cursor/cat-pow.png"), none !important;
}

.checkbox_wrapper_5 .check label {
  width: calc(2.2 * var(--size));
  height: var(--size);
  overflow: hidden;
  background: rgba(36, 28, 42, 0.92);
  border: 1px solid #ffffff;
  cursor: url("../../../../public/images/cursor/cat-pow.png"), none !important;
}

.checkbox_wrapper_5 .check input[type="checkbox"] {
  position: absolute;
  z-index: 1;
  width: calc(0.8 * var(--size));
  height: calc(0.8 * var(--size));
  top: calc(0.1 * var(--size));
  left: calc(0.1 * var(--size));
  background: rgba(36, 28, 42, 0.92);
  background-image: url("../../../../public/images/switch/darkMode.svg");
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #ffffff;
  box-shadow: 0 6px 7px rgba(0, 0, 0, 0.3);
  outline: none;
  margin: 0;
}

.checkbox_wrapper_5 .check input[type="checkbox"]:checked {
  left: calc(1.3 * var(--size));
  background-color: #c3becd !important;
  background-image: url("../../../../public/images/switch/lightMode.svg");
  cursor: url("../../../../public/images/cursor/cat-pow.png"), none !important;
}

.checkbox_wrapper_5 .check input[type="checkbox"]:checked + label {
  background: transparent;
  cursor: url("../../../../public/images/cursor/cat-pow.png"), none !important;
}

.checkbox_wrapper_5 .check label::before,
.checkbox_wrapper_5 .check label::after {
  position: absolute;
  overflow: hidden;
  left: calc(0.15 * var(--size));
  top: calc(0.5 * var(--size));
  height: var(--size);
  letter-spacing: calc(-0.04 * var(--size));
  color: #9b9b9b;
  z-index: 2;
  font-size: calc(0.6 * var(--size));
  border-radius: 0;
  transform-origin: 0 0 calc(-0.5 * var(--size));
  backface-visibility: hidden;
  cursor: url("../../../../public/images/cursor/cat-pow.png"), none !important;
}

.checkbox_wrapper_5 .check label::after {
  top: calc(0.65 * var(--size));
  left: calc(0.2 * var(--size));
  height: calc(0.1 * var(--size));
  width: calc(0.35 * var(--size));
  font-size: calc(0.2 * var(--size));
  transform-origin: 0 0 calc(-0.4 * var(--size));
  cursor: url("../../../../public/images/cursor/cat-pow.png"), none !important;
}

.checkbox_wrapper_5 .check input[type="checkbox"]:checked + label::before,
.checkbox_wrapper_5 .check input[type="checkbox"]:checked + label::after {
  left: calc(1.55 * var(--size));
  top: calc(0.4 * var(--size));
  line-height: calc(0.1 * var(--size));
  transform: rotateY(360deg);
  cursor: url("../../../../public/images/cursor/cat-pow.png"), none !important;
}

.checkbox_wrapper_5 .check input[type="checkbox"]:checked + label::after {
  height: calc(0.16 * var(--size));
  top: calc(0.55 * var(--size));
  left: calc(1.6 * var(--size));
  font-size: calc(0.6 * var(--size));
  line-height: 0;
  cursor: url("../../../../public/images/cursor/cat-pow.png"), none !important;
}

@media (max-width: $breakpoint-tablet) {
  .checkbox_wrapper_5 .check {
    --size: 30px !important;
  }
}
