@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../assets/fonts/Poppins-Bold.eot");
  src: url("../../assets/fonts/Poppins-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/Poppins-Regular.woff2") format("woff2"),
    url("../../assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../assets/fonts/Poppins-Medium.eot");
  src: url("../../assets/fonts/Poppins-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/Poppins-Medium.woff2") format("woff2"),
    url("../../assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../assets/fonts/Poppins-Bold.eot");
  src: url("../../assets/fonts/Poppins-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/Poppins-Bold.woff2") format("woff2"),
    url("../../assets/fonts/Poppins-Bold.ttf") format("truetype");
}
