@import "../../variables.scss";

.wrapper {
  padding: 8rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 10rem;
      height: 10rem;
      margin-left: -10rem;
    }
    h1 {
      font-weight: 500;
    }
    svg {
      width: 4rem;
      height: 4rem;
      margin-left: 2rem;
      margin-bottom: 2rem;
    }
  }
  .section {
    padding: 3rem 6rem;
    margin-bottom: 1.125rem;
    border-radius: 55px;
    font-size: $font-size-large;
    font-weight: lighter;
    position: relative;
    svg {
      width: 40px;
      height: 40px;
    }
    .pow1 {
      position: absolute;
      left: 1rem;
      transform: rotate(-50deg);
    }
    .pow2 {
      position: absolute;
      right: 1rem;
    }
    .pow3 {
      position: absolute;
      left: 1rem;
      bottom: 2rem;
      transform: rotate(-50deg);
      svg {
        width: 70px;
        height: 70px;
      }
    }
  }
  .education {
    margin-top: 4rem;
    display: flex;
    .title {
      flex-direction: column;
      justify-content: center;
      h1 {
        margin: 0;
      }
      img {
        margin-left: 0;
        width: 200px;
      }
    }
    .separator {
      width: 0.125rem;
      margin: 0 2rem;
    }
    .info {
      display: flex;
      position: relative;
      .texts {
        .uni {
          font-size: $font-size-large;
        }
        .deg {
          font-size: $font-size-medium;
        }
        .period {
          font-size: $font-size-small;
        }
        .gpa {
          font-size: $font-size-xsmall;
        }
      }
      .pows {
        display: flex;
        position: absolute;
        bottom: -5rem;
        right: -11rem;
        .pow4 {
          svg {
            width: 5rem;
            height: 5rem;
          }
        }
        .pow5 {
          svg {
            width: 8rem;
            height: 10rem;
          }
        }
      }
    }
  }
  &.light {
    .section {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='55' ry='55' stroke='%23724F8CFF' stroke-width='4' stroke-dasharray='10 15' stroke-dashoffset='100' stroke-linecap='butt'/%3e%3c/svg%3e");
    }
    .education {
      .separator {
        background-image: url("./verticalLine-light.svg");
      }
    }
  }
  &.dark {
    .section {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='55' ry='55' stroke='%23C890EFFF' stroke-width='4' stroke-dasharray='10 15' stroke-dashoffset='100' stroke-linecap='butt'/%3e%3c/svg%3e");
    }
    .education {
      .separator {
        background-image: url("./verticalLine-dark.svg");
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .wrapper {
    width: calc(100% - 3rem);
    padding: 5rem 1.5rem;
    .title {
      .title_image {
        width: 7rem;
        height: 7rem;
        margin-right: 5rem;
        position: absolute;
      }
      svg {
        position: absolute;
        width: 3rem;
        height: 3rem;
        margin-left: 14rem;
        margin-bottom: 1rem;
      }
    }
    .section {
      padding: 3rem 2rem;
      .pow1 {
        top: 0.75rem;
        svg {
          width: 30px;
          height: 30px;
        }
      }
      .pow3 {
        left: 6rem;
        bottom: 1rem;
        svg {
          width: 40px;
          height: 40px;
        }
      }
    }
    .education {
      .title {
        .education_image {
          width: 100%;
          height: 55%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
