@import "../../variables.scss";

.wrapper {
  height: calc(100% - 6rem);
  width: calc(100% - 8rem);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 3rem 3rem 3rem 5rem;

  .info {
    max-width: 45rem;
    svg {
      width: 60px;
      height: 60px;
      padding-left: 3rem;
    }
    h1 {
      font-size: 3rem !important;
      font-weight: 500;
    }
    .description {
      font-size: $font-size-large !important;
      margin-bottom: 3rem;
    }
    .hire_me {
      text-decoration: none;
    }
  }
  .image {
    max-width: 500px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .wrapper {
    width: calc(100% - 3rem);
    padding: 3rem 1.5rem;
    .info {
      max-width: 100%;
      svg {
        width: 50px;
        height: 50px;
        padding-left: 0;
        margin-bottom: -1rem;
      }
      .hire_me {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .image {
      max-width: 100%;
    }
  }
}
