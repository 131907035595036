@import "../../variables.scss";

.wrapper {
  padding: 8rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 8rem);
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      font-weight: 500;
    }
    .pow1 {
      svg {
        width: 5rem;
        height: 5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        transform: rotate(-50deg);
      }
    }
    .pow2 {
      svg {
        width: 4rem;
        height: 4rem;
        margin-left: 2rem;
        margin-bottom: -2rem;
      }
    }
  }
  .section_animation_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .section {
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;
    .images {
      display: flex;
      position: relative;
      .pows {
        display: flex;
        position: absolute;
        left: -1rem;
        top: -3rem;
        .pow3 {
          svg {
            width: 4rem;
            height: 4rem;
            transform: rotate(-40deg);
          }
        }
        .pow4 {
          svg {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
    img {
      width: 15rem;
      height: 15rem;
    }
    .info {
      h3 {
        font-size: $font-size-large;
      }
      h4 {
        font-size: $font-size-medium;
      }
      h5 {
        font-size: $font-size-small;
        margin-bottom: 5rem;
      }
    }
    ul {
      text-decoration: none;
      li {
        font-size: $font-size-small;
        padding-bottom: 1rem;
      }
    }
    .porsline {
      background-repeat: no-repeat;
      padding: 4rem;
      padding-right: 0;
      width: 829px;
    }
    .mobtaker {
      background-repeat: no-repeat;
      padding: 7rem;
      padding-right: 0;
      width: calc(726px - 7rem);
      display: flex;
      justify-content: space-between;
      .pow5 {
        align-self: flex-end;
        margin-left: 3rem;
        svg {
          width: 8rem;
          height: 8rem;
        }
      }
    }
    .tis {
      background-repeat: no-repeat;
      padding: 4rem;
      padding-right: 0;
      width: calc(1041px - 4rem);
      position: relative;
      .pow6 {
        position: absolute;
        top: 11rem;
        left: 22rem;
        transform: rotate(-50deg);
        svg {
          width: 4rem;
          height: 4rem;
        }
      }
      ul {
        width: 80%;
        padding-bottom: 2.5rem;
      }
      .stack {
        font-size: 13px;
      }
    }
  }
  .section:nth-child(2) {
    justify-content: center !important;
  }
  .section:nth-child(3) {
    justify-content: flex-end;
    gap: 5rem;
  }
  &.light {
    .section {
      .porsline {
        background-image: url("../experience/shapes/light/shape1.svg");
      }
      .mobtaker {
        background-image: url("../experience/shapes/light/shape2.svg");
      }
      .tis {
        background-image: url("../experience/shapes/light/shape1.svg");
      }
    }
  }
  &.dark {
    .section {
      .porsline {
        background-image: url("../experience/shapes/dark/shape1.svg");
      }
      .mobtaker {
        background-image: url("../experience/shapes/dark/shape2.svg");
      }
      .tis {
        background-image: url("../experience/shapes/dark/shape1.svg");
      }
    }
  }
}

@media (max-width: 1350px) {
  .tis,
  .mobtaker,
  .porsline {
    background-image: none !important;
  }
  .mobtaker {
    width: initial !important;
  }
}

@media (max-width: $breakpoint-tablet) {
  .wrapper {
    width: calc(100% - 3rem);
    padding: 5rem 1.5rem;
    .title {
      .pow1 {
        svg {
          width: 4rem;
          height: 4rem;
          margin-left: 0rem;
          margin-right: 1rem;
        }
      }
    }
    .section {
      .porsline,
      .mobtaker,
      .tis {
        padding: 0;
        margin-top: 3rem;
      }
    }
    ul {
      width: 100%;
      padding-left: 1rem;
    }
    .mobtaker {
      flex-direction: column;
    }
  }
  .section:nth-child(3) {
    justify-content: initial !important;
    gap: 0 !important;
  }
  .info {
    display: flex !important;
    justify-content: space-between !important;
    img {
      width: 50% !important;
      height: 50% !important;
    }
  }
}
