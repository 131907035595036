.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  p {
    text-align: center;
  }
  .image_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
