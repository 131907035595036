@import "./variables.scss";
@import "./fonts.scss";

html {
  overflow-x: hidden;
}
html,
body {
  height: 100%;
  margin: 0;
  font-family: Poppins;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 120px;
  }
  &::-webkit-scrollbar-thumb {
    background-image: url("../../public/images/cat-scratch.png");
    background-size: cover;
  }
  &.light {
    &::-webkit-scrollbar-track {
      background-image: linear-gradient(#d3ccdc, #d3cde3);
    }
  }
  &.dark {
    &::-webkit-scrollbar-track {
      background-image: linear-gradient(#241c2e, #241c30);
    }
  }
}
#root {
  width: 100%;
  transition: 0.35s ease-in-out;
}

@media (max-width: $breakpoint-tablet) {
  html,
  body {
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  body {
    overflow-x: hidden;
  }
}
