@import "../../variables.scss";

.wrapper {
  padding: 1rem;
  .pows {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 2rem;
    .pow1 {
      svg {
        width: 70px;
        height: 70px;
      }
    }
    .little_pows {
      position: relative;
      .pow2 {
        position: absolute;
        transform: rotate(-50deg);
        svg {
          width: 40px;
          height: 40px;
          g {
            path {
              fill: #cfaee7;
            }
          }
        }
      }
      .pow3 {
        position: absolute;
        top: 2rem;
        left: 1.5rem;
        svg {
          width: 30px;
          height: 30px;
          g {
            path {
              fill: #cfaee7;
            }
          }
        }
      }
    }
  }
  nav {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 0 1rem;
    a {
      color: white;
      text-align: none;
      text-decoration: none;
      font-size: $font-size-large;
      font-weight: 500;
    }
  }
  .pows_menu_footer {
    position: absolute;
    bottom: 1rem;
  }
}
