@import "../../variables.scss";

.header {
  width: calc(100% - 1rem);
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 1rem;
  padding-right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: all 0.6s;
  .navbar {
    width: 50%;
    font-weight: 500;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: $font-size-xlarge;
    a {
      text-decoration: none;
      cursor: url("../../../../public/images/cursor/cat-pow.png"), none !important;
      &:hover {
        color: #744d87;
      }
    }
  }
  .intouch {
    display: flex;
    gap: 1rem;
    .pows {
      display: flex;
      .pow1 {
        align-self: flex-end;
        transform: rotate(310deg);
        svg {
          width: 30px;
          height: 30px;
        }
      }
      .pow2 {
        transform: rotate(-10deg);
        svg {
          width: 60px;
          height: 60px;
        }
      }
    }
    .menu {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.hide {
    top: -7rem;
  }
  &:hover {
    top: 0 !important;
  }
  &.light {
    background-color: rgba(216, 204, 221, 0.92);
    a {
      color: $text-color-light;
    }
  }
  &.dark {
    background-color: rgba(36, 28, 42, 0.92);
    a {
      color: $text-color-dark;
    }
    .menu {
      svg {
        path {
          fill: $text-color-dark;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .header {
    padding: 1rem 0.5rem;
    width: calc(100% - 1rem);
  }
}
