@import ".././variables.scss";

.container {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: repeat;
  transition: 0.35s ease-in-out;

  .view {
    height: calc(100% - 7rem);
    position: relative;
  }
  .footer {
    background-image: url("../../../public/images/pows-footer-desktop.png");
    height: 350px;
    width: 100%;
    background-repeat: repeat-x;
  }
  &.light_bg {
    background-color: $background-color-light;
    color: $text-color-light;
  }
  &.dark_bg {
    background-color: $background-color-dark;
    color: $text-color-dark;
  }
}

@media (max-width: 700px) {
  .footer {
    background-image: url("../../../public/images/pows-footer-mobile.png") !important;
    height: 120px !important;
  }
}
